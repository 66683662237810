import React from "react"
import { Row, Col, Container, Card, Button, Image } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SlideShow from "../components/slider"
// import Blurb from "../components/blurb"
import Header from "../components/header"
import autoguardLogo from "../images/autoguard-logo.png"
import protectionLogo from "../images/protection-logo2.png"
import tireLogo from "../images/tire-logo-full.png"
import theftLogo from "../images/anti-theft-logo-full.png"
import keyLogo from "../images/key-logo-full.png"

export const query = graphql`
  {
    autoguard: file(relativePath: { eq: "autoguard-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    platinumEntry: file(relativePath: { eq: "platinum-entry-whole.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bumperRepair: file(relativePath: { eq: "bumper-repair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keyLogo: file(relativePath: { eq: "key-logo-full.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keySand: file(relativePath: { eq: "key-sand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fobHand: file(relativePath: { eq: "key-fob-hand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tireLogo: file(relativePath: { eq: "tire-logo-full.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flatTire: file(relativePath: { eq: "flat-tire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    antiTheftLogo: file(relativePath: { eq: "anti-theft-logo-full.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jacker: file(relativePath: { eq: "jacker.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fastCar: file(relativePath: { eq: "fast-car.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    memberLogo: file(relativePath: { eq: "memberships.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    protection: file(relativePath: { eq: "protection-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const bumperFix = data.bumperRepair.childImageSharp.fluid
  const fastCar = data.fastCar.childImageSharp.fluid
  const keySand = data.keySand.childImageSharp.fluid
  const jacker = data.jacker.childImageSharp.fluid
  const logo = data.memberLogo.childImageSharp.fluid
  const content = [
    {
      title: "Autoguard",
      description: "Keep your car looking new for years to come!",
      button: "Read More",
      image: `${bumperFix.src}`,
      page: "/autoguard",
    },
    {
      title: "Tire and Rim",
      description: "Covers damage caused by road hazard",
      button: "Read More",
      image: `${fastCar.src}`,
      page: "/tire-rim",
    },
    {
      title: "Key and Remote",
      description: "Protect against lost or broken keys",
      button: "Read More",
      image: `${keySand.src}`,
      page: "/key-remote",
    },
    {
      title: "Anti-Theft Protection",
      description: "Protect your vehicle against thieves",
      button: "Read More",
      image: `${jacker.src}`,
      page: "/anti-theft",
    },
  ]
  //  use if text is wanted in the future
  // const text =
  //   "The glass body accelerated the galvanized seat belt. The galvanized, aluminum GVWR upfitted when the seat belt constructed the 4x4 rollback body. The tough, upfitted stake bed trucked. The lifted lift kit was dumped by the semi. The shock-resistant snowplow was manufactured by the glass body. Once the stabilizer bar drove the refrigerated body therefore once the service utility van constructed the NQR. The empty cargo van dumped the upfitted ProMaster 1500 but once the pickup decelerated the wrecker body."

  return (
    <Layout className="index-container mt-1" pageInfo={{ pageName: "index" }}>
      <Seo title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
      <SlideShow data={content} />
      <Header logo={logo.src} />
      {/* Use if text is wanted in the future */}
      {/* <Blurb text={text} /> */}
      <Container fluid>
        <Row className="my-2 py-2" display="flex">
          <Col
            className="d-flex justify-content-end"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={12}>
                <Card className="card-green" border="dark" bg="light">
                  <Card.Body>
                    <Card.Img
                      src={keyLogo}
                      variant="top"
                      alt="Key Logo"
                      className="page-logo-img pb-2"
                    />
                    <Card.Title>Cover costs associated with:</Card.Title>
                    <Card.Text></Card.Text>
                    <ul>
                      <li>Deprogramming your previous key/remote</li>
                      <li>Purchasing new/refurbished key/remote</li>
                      <li>Reprogramming new key/remote</li>
                    </ul>
                    <div className="text-center">
                      <Link to="/key-remote">
                        <Button className="key-btn text-center">
                          Key/Remote Info
                        </Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex justify-content-start"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={12}>
                <Card className="card-red" border="dark" bg="light">
                  <Card.Body text="black">
                    <Card.Img
                      className="page-logo-img  pb-2"
                      variant="top"
                      src={autoguardLogo}
                      alt="Autoguard"
                    />
                    <Card.Title text="black">
                      Autoguard Services Include:
                    </Card.Title>
                    <Card.Text></Card.Text>
                    <ul>
                      <li>Door Dings</li>
                      <li>Exterior Scratches</li>
                      <li>Interior Cuts & Tears</li>
                    </ul>
                    <div className="text-center">
                      <Link to="/autoguard">
                        <Button className="autoguard-btn">
                          Autoguard Info
                        </Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-2 py-2 pb-10">
          <Col
            className="d-flex align-items-center justify-content-end"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={12}>
                <Card className="card-orange" border="dark" bg="light">
                  <Card.Body>
                    <Card.Img
                      className="page-logo-img  pb-2"
                      variant="top"
                      src={tireLogo}
                      alt="Tires and Rims"
                    />
                    <Card.Title>
                      Available For New and Used Vehicles:
                    </Card.Title>
                    <Card.Text></Card.Text>
                    <ul>
                      <li>Covers original or replacement tires</li>
                      <li>Includes tire change or towing</li>
                      <li>No deductible!</li>
                    </ul>
                    <div className="text-center">
                      <Link to="/tire-rim">
                        <Button className="tire-btn text-center">
                          Tire/Rim Info
                        </Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex justify-content-start"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={12}>
                <Card className="card-yellow" border="dark" bg="light">
                  <Card.Body>
                    <Card.Img
                      className="page-logo-img  pb-2"
                      variant="top"
                      src={theftLogo}
                      alt="Anti-Theft"
                    />
                    <Card.Title>Protect yourself against:</Card.Title>
                    <Card.Text></Card.Text>
                    <ul>
                      <li>Helps Prevent Thefts</li>
                      <li>Traceable Identification Numbers</li>
                      <li>No Monitoring Fees</li>
                    </ul>
                    <div className="text-center">
                      <Link to="/anti-theft">
                        <Button className="theft-btn text-center">
                          Anti-Theft Info
                        </Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-2 py-2 pb-10" style={{ justifyContent: "center" }}>
          <Col
            className="d-flex align-items-center justify-content-center"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={12}>
                <Card className="card-blue" border="dark" bg="light">
                  <Card.Body>
                    <Card.Img
                      as={Image}
                      className="page-logo-img  pb-2"
                      variant="top"
                      src={protectionLogo}
                      alt="Tires and Rims"
                      fluid={true}
                    />
                    <Card.Title>
                      Protect Your Vehicle's Interior and Exterior:
                    </Card.Title>
                    <Card.Text></Card.Text>
                    <ul>
                      <li>Protect Interior and Exterior</li>
                      <li>Undercoat Spray</li>
                      <li>Paint Protection</li>
                    </ul>
                    <div className="text-center">
                      <Link to="/protection">
                        <Button className="protection-btn text-center">
                          Protection Info
                        </Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
